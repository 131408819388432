@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer base {
	.cta-base {
		@apply text-xl;
		@apply text-center;
		@apply text-white;
		@apply font-bold;
		@apply bg-green-400;
		@apply py-3;
		@apply px-6;
		@apply rounded-full;
		@apply shadow-lg;
		@apply hover:bg-yellow-400;
		@apply cursor-pointer;
	}

	.bullet-point-text {
		@apply text-xl;
		@apply font-bold;
	}

	.offer-bullet-point-text {
		@apply text-lg;
		@apply font-bold;
	}

	.icon-size {
		@apply w-6;
		@apply h-6;
		@apply flex-shrink-0;
	}

	.usage-text {
		@apply font-bold;
		@apply text-xl;
	}
}